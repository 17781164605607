import { nanoid } from 'nanoid';
export default {
  props: {
    question: { type: Object, required: true }
  },
  computed: {
    options() {
      return (this.question && this.question.options) ?? [];
    }
  },
  methods: {
    optionAdd() {
      const before = this.options || [];
      const num = before.length + 1;
      const id = nanoid(5);
      const options = [...before, { id, label: `Option ${num}` }];
      const question = { ...this.question, options };
      this.$emit('change', question);
    },
    optionChange(index, label) {
      let options = [...this.options];
      const before = options[index];
      const option = { ...before, label };
      options.splice(index, 1, option);
      const question = { ...this.question, options };
      this.$emit('change', question);
    },
    optionRemove(index) {
      let options = [...this.options];
      options.splice(index, 1);
      const question = { ...this.question, options };
      this.$emit('change', question);
    }
  }
};
