import { nanoid } from 'nanoid';
export default {
  props: {
    question: { type: Object, required: true }
  },
  computed: {
    rows() {
      return (this.question && this.question.rows) ?? [];
    }
  },
  methods: {
    rowAdd() {
      const before = this.rows;
      const num = before.length + 1;
      const id = nanoid(5);
      const rows = [...before, { id, label: `Row ${num}` }];
      const question = { ...this.question, rows };
      this.$emit('change', question);
    },
    rowChange(index, label) {
      let rows = [...this.rows];
      const before = rows[index];
      const row = { ...before, label };
      rows.splice(index, 1, row);
      const question = { ...this.question, rows };
      this.$emit('change', question);
    },
    rowRemove(index) {
      let rows = [...this.rows];
      rows.splice(index, 1);
      const question = { ...this.question, rows };
      this.$emit('change', question);
    }
  }
}
