<template>
  <div>
    <div class="flex">
      <div class="flex-1">
        <div
          v-for="(row, index) in rows"
          :key="row.id"
          class="flex items-center"
        >
          <!-- <div class="pt-1 pr-4 text-gray-400">
            <fa :icon="['far', 'circle']" fixed-width />
          </div> -->
          <div>
            <sqr-input-text
              :value="row.label"
              @change="rowChange(index, $event)"
            />
          </div>
          <div class="pt-1 px-2">
            <sqr-button icon="trash" @click="rowRemove(index)" color="white" />
          </div>
        </div>
        <div class="mt-3">
          <sqr-button
            icon="plus"
            label="add a row"
            color="blue"
            light
            @click="rowAdd()"
          />
        </div>
      </div>

      <div class="flex-1">
        <div
          v-for="(option, index) in options"
          :key="option.id"
          class="flex items-center"
        >
          <div class="pt-1 pr-4 text-gray-400">
            <fa :icon="['far', 'circle']" fixed-width />
          </div>
          <div>
            <sqr-input-text
              :value="option.label"
              @change="optionChange(index, $event)"
            />
          </div>
          <div class="pt-1 px-2">
            <sqr-button
              icon="trash"
              @click="optionRemove(index)"
              color="white"
            />
          </div>
        </div>
        <div class="mt-3">
          <sqr-button
            icon="plus"
            label="add an option"
            color="blue"
            light
            @click="optionAdd()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SqrInputText from '@/sqrd-ui/SqrInputText';
import SqrButton from '@/sqrd-ui/SqrButton';
import rows from '../rows';
import options from '../options';

export default {
  name: 'GridRadioEdit',
  mixins: [rows, options],
  components: { SqrInputText, SqrButton }
};
</script>
